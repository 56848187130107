import '../../../styles/index.scss';

import dominos from './data/dominos.json';
import kfc from './data/kfc.json';
import mac from './data/mac.json';

import { Dish } from "./classDish.js";

window.addEventListener('DOMContentLoaded', (event) => {
  
  const restaurantMenu = document.querySelector('.tabs__content');
  const featured = document.querySelector('.featured');
  const restaurants = document.querySelectorAll('.featured__item');
  const dominosArr = JSON.parse(JSON.stringify(dominos));
  const kfcArr = JSON.parse(JSON.stringify(kfc));
  const macArr = JSON.parse(JSON.stringify(mac));
  const allDishesArr = [...dominosArr, ...kfcArr, ...macArr];
  let allDrawerArr = [];
  const shipmentOrders = []; 
  const DOMINOS = 'Domino’s Pizza';
  const KFC = 'KFC';
  const MACDONALDS = 'McDonald’s';
  
  const drawerShowBtn = document.getElementById('purcasheBtn');
  const purcasheCount = drawerShowBtn.querySelector('span.icon-button__badge');
  const totalOrderPrice = document.getElementById('totalPrice');
  const checkoutBtn = document.getElementById('checkout');

  const drawer = document.getElementById('drawer');
  const hideCartBtn = document.getElementById('hideDrawerBtn');
  const deliverCosts = document.getElementById('deliveryPrice'); 
  const hideCartBtn2 = document.querySelector('.add-more');
  const order = drawer.querySelector('.order');
  
  let cards;
  let deliveryPrice = 0;



  const getDish = ((dish) => {

    const { id, price, title, img} = dish;
    return `<div class="dish" id="dish-${id}">
              <img class="dish__image" src="${img}" alt="">
              <div class="dish__title">${title}</div>
              <div class="dish__info">
                <div class="dish__price">${price} $</div>
                <div class="counter">
                  <button style="display: none;" data-id="1" class="counter__button counter__button--decrease"></button>
                  <span class="counter__number">${dish.getCount()}</span>
                  <button data-id=${id} class="counter__button counter__button--increase"></button>
                </div>
              </div>
            </div>`;
  });

  const generateMenuHTML = ((restaurant) => {
    
    const dishHTML = restaurant.map((item) => {
      return getDish(new Dish(item));
    });
    
    restaurantMenu.innerHTML = '';
    restaurantMenu.insertAdjacentHTML('afterbegin', dishHTML);
    cards = document.querySelectorAll('.dish'); 
  });

  const showProducts = ((event)=> {
    event.preventDefault();
    allDrawerArr.length = 0;
    purcasheCount.innerHTML = allDrawerArr.length;
    drawer.querySelector('.subtitle').innerHTML = `(${allDrawerArr.length} items)`;
    // order.previousElementSibling.innerHTML = event.target.closest('.featured__item').querySelector('h4').innerHTML;
    totalOrderPrice.innerHTML = '';
    restaurants.forEach((restaurant) => {
      restaurant.classList.remove('active');
    });
    
    if (event.target.closest('.featured__item')) {
      
      event.target.closest('.featured__item').classList.add('active');
      event.target.closest('.featured__item').querySelector('[data-delivery-price]').dataset.deliveryPrice ? deliveryPrice = `${event.target.closest('.featured__item').querySelector('[data-delivery-price]').dataset.deliveryPrice}` : 0;
      
      deliverCosts.innerHTML = `${event.target.closest('.featured__item').querySelector('[data-delivery-price]').dataset.deliveryPrice}$`;
      if (event.target.closest('.featured__item').querySelector('.h4').innerHTML === MACDONALDS) {
        generateMenuHTML(macArr);
        order.previousElementSibling.innerHTML = MACDONALDS;
      }

      if (event.target.closest('.featured__item').querySelector('.h4').innerHTML === KFC) {
        generateMenuHTML(kfcArr);
        order.previousElementSibling.innerHTML = KFC;
      }

      if (event.target.closest('.featured__item').querySelector('.h4').innerHTML === DOMINOS) {
        generateMenuHTML(dominosArr);
        order.previousElementSibling.innerHTML = DOMINOS;
      }

      cards.forEach((card) => {
        card.addEventListener('click', countFn);
      });
    }
  });

  function countFn(event) {
    const count = this.querySelector('.counter__number');
    const minusBtn = this.querySelector('.counter__button--decrease');
    const plusBtn = this.querySelector('.counter__button--increase');
    const el = document.getElementById(`${this.id}`);
    const title = el.childNodes[3].innerHTML;
    const targetObj = allDishesArr.find((dish) => dish.title === title);
    const dish = new Dish(targetObj);
    let oldDeliveryPrice = deliveryPrice;
    if (event.target === plusBtn) {
      count.innerHTML = Number(count.innerHTML) + 1;
      dish.setCount(+count.innerHTML);
      // dish.setCount(Number(dish.getCount())+1);
      const isRepeat = allDrawerArr.every((dish) => dish.title === title);
      const idx = allDrawerArr
          .map((dish) => dish.title)
          .indexOf(title);
      if (idx !== -1) {
        allDrawerArr.splice(idx, 1,dish);
        // allDrawerArr.push(dish);
      } else {
        allDrawerArr.push(dish);
      }
    }

    if (+count.innerHTML >= 1) {
      if (minusBtn.getAttribute('style') === 'display: none;') {
        minusBtn.style.display = 'inherit';
      }
    }

    if (event.target === minusBtn) {
      count.innerHTML = Number(count.innerHTML) - 1;
      try {
        dish.setCount(Number(count.innerHTML));
      } catch (error) {
        console.log(error.message);
      }
    }

    if (+count.innerHTML < 1) {
      if (minusBtn.getAttribute('style') === 'display: inherit;') {
        minusBtn.style.display = 'none';
      }
      const idx = allDrawerArr
        .map((dish) => dish.title)
        .indexOf(title);
      allDrawerArr.splice(idx, 1);
    }

    if ( allDrawerArr.length < 1) {
      deliveryPrice = 0;
    } else {
      deliveryPrice = oldDeliveryPrice;
    }

    purcasheCount.innerHTML = allDrawerArr.length;
    drawer.querySelector('.subtitle').innerHTML = `(${allDrawerArr.length} items)`;

    const orderTotalPrice =
      allDrawerArr
        .reduce((totalPrice, currentPrice) => totalPrice + currentPrice.getCount() * currentPrice.price, Number(deliveryPrice));
    totalOrderPrice.innerHTML = orderTotalPrice;
    console.log('orderTotalPrice', orderTotalPrice);
    console.log(allDrawerArr);
    // console.log("dishCOUNT===>,", dish.getCount());
    // console.log(dish.getCount());
    // console.log(this===event.currentTarget); // true
  }

  function showDrawer() {
    drawer.classList.toggle('visible');
  }
  function hideDrawer() {
    drawer.classList.toggle('visible');
    order.previousElementSibling.innerHTML = '';
  }

  const getDishHTML = ((dish) => {
    const { id, price, title, img} = dish;
    return `<div class="order__item order-item"><img class="order-item__image" src="${img}" alt="">
            <span id="${id}" class="order-item__quantity">${dish.getCount()} X</span>
            <div class="order-item__info">
              <h3 class="order-item__title h3">${title}</h3>
              <div class="order-item__price">${price} $</div>
            </div>
            <button class="icon-button icon-button--red"><img src="img/icons/delete.svg" alt=""></button></div>`;
  });

  const generateDrawerHTML = (() => {
    order.innerHTML = '';
      
    const dishHTML = allDrawerArr.map((item) => {
      return getDishHTML(item);
    });
    
    order.insertAdjacentHTML('afterbegin', dishHTML);
  });

  function placeInOrder() {
    const allDrawerArrToStorage = allDrawerArr
      .map((order) => { 
        delete order.img;
        order.count= order.getCount(); 
      return order;
    });

    const obj = {
      restaurant:order.previousElementSibling.innerHTML,
      checkout: new Date().toISOString(),
      orders:  allDrawerArrToStorage,
    };

    if (window.localStorage.getItem('order')) {
      const totalOrdersArr = JSON.parse(window.localStorage.getItem('order'));
      totalOrdersArr.push(obj);
      window.localStorage.setItem('order', JSON.stringify(totalOrdersArr));
    } else {
      window.localStorage.setItem('order', JSON.stringify([obj]));
    }
    document.location.href = 'orders.html';    
  }

  generateMenuHTML(dominosArr); 

  cards.forEach(card => {
    card.addEventListener('click', countFn);
  });

  featured.addEventListener('click', showProducts);
  drawerShowBtn.addEventListener('click', generateDrawerHTML);
  drawerShowBtn.addEventListener('click', showDrawer);
  hideCartBtn.addEventListener('click', hideDrawer);
  hideCartBtn2.addEventListener('click', hideDrawer);
  checkoutBtn.addEventListener('click', placeInOrder);
  
});




// generateMenuHTML(kfcArr);

// const orders = dominosArr.map((item) => new Dish(item));
// console.log('orders',orders);

// getDishes(dominosArr[1]);

// console.log('dominos',JSON.parse(JSON.stringify(dominos)));
// console.log('kfc',JSON.parse(JSON.stringify(kfc)));
// console.log('mac',JSON.parse(JSON.stringify(mac)));

// const orders = dominos.map((item) => new Dish(item));
// console.log(orders);
// console.log(dominos);
// const dish = new Dish;
// dish.setCount();
// console.log(dish.getCount());