export  class Dish {
  #count;

  constructor( obj ) {
    this.id = obj.id;
    this.price = obj.price;
    this.title = obj.title;
    this.img = obj.img;
    this.#count = obj.count;
  }
  
  getCount() {
    return this.#count;
  }

  setCount(count) {
    if (typeof count !== 'number') {
      throw new Error(`неверное значение ==>${count}<== `);
    }
    if (count <= 0) {
      throw new Error(`here value is ==>${count}<== , and it should remove from cart`);
    }
    return this.#count = count;
  }
}